const IbrDetailReducers = (state, action) => {
  switch (action.type) {
    case "SET_IBR_DATA":
      // console.log("Updating IBR Data:", {
      //   ...state?.ibrDetail,
      //   ...action.payload,
      // });
      return {
        ...state,
        ibrDetail: { ...state?.ibrDetail, ...action.payload },
        isFetching: false,
        error: false,
      };
    case "REMOVE_IBR_DATA":
      // console.log("REMOVE_IBR_DATA");
      return {
        ibrDetail: null,
        isFetching: false,
        error: false,
      };
    default:
      return state;
  }
};

export default IbrDetailReducers;
